<template>
  <navbar-page :profile-section="true" class="body">
    <loading-suspense :loading="loading" :error="error" class="loading">
      <template v-if="dataLoaded">
        <greeting-section :profile="profile"/>
        <program-section
          :program="program"
          :reward-response="rewardResponse"
          :required-activities-not-completed-count="requiredActivitiesNotCompletedCount"
          :to="to"
          :company-logo="company?.logo"
        />
        <div class="todo-progress-container">
          <todo-section :current-day="currentDay" @todos-updated="refreshDashboard"/>
          <progress-section
            :steps-value="stepsValue"
            :activity-value="activityValue"
            :sleep-value="sleepValue"
          />
        </div>
      </template>
    </loading-suspense>
    <add-tracking-button @tracking-added="refreshDashboard"/>
  </navbar-page>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import AddTrackingButton from "@/vue/atoms/add-tracking-button.vue";
import GreetingSection from "@/vue/organisms/dashboard/greeting-section.vue";
import ProgramSection from "@/vue/organisms/dashboard/program-section.vue";
import TodoSection from "@/vue/organisms/dashboard/todo-section.vue";
import ProgressSection from "@/vue/organisms/dashboard/progress-section.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {mixins} from "vue-class-component";
import {mixinClassBodyFactory} from "@/ts/mixins/bg-mixin";
import {LoadingMixin} from "@/ts/mixins/loading-mixin";
import {CachedApiMixin} from "@/ts/mixins/cached-api-mixin";
import {GrowlsStoreMixin} from "@/ts/store/growl/growl-store-instance";
import {today, convertDateToServerFormat, getMondayOfTheWeek, addToDate, getRemainNumberOfDays, daysSince, formatDate} from "@/ts/utils/date-pure-functions";
import {DefaultState} from "@/ts/store/default/default-store-instance";
import type {Profile, Program} from "@/ts/types/store/default-store-types";
import type {RewardTrackerResponse} from "@/ts/types/dto/reward-tracker";
import type {Api} from "@/ts/classes/api";
import type {GatekeeperItem} from "@/ts/types/dto/program.dto";
import {GatekeeperStatus} from "@/ts/types/dto/program.dto";
import {HealthContentActivityResponseType} from "@/ts/types/dto/health.dto";
import {inject} from "vue";
import {TaskType} from "@/ts/types/dto/activity.dto";
import type {CompanyDTO} from "@/ts/types/dto/company.dto";

@Component({
  name: "DashboardPage",
  components: {
    AddTrackingButton,
    NavbarPage,
    GreetingSection,
    ProgramSection,
    TodoSection,
    ProgressSection,
    LoadingSuspense,
  },
})
export default class DashboardPage extends mixins(
  mixinClassBodyFactory("global-onboarding-bg-color"),
  LoadingMixin,
  CachedApiMixin,
  GrowlsStoreMixin,
) {
  currentDay: Date = today();

  requiredActivitiesNotCompletedCount: number = 0;

  api: Api = inject("$api")!;

  @DefaultState
  public profile!: Profile;

  @DefaultState
  public program!: Program;

  rewardResponse: RewardTrackerResponse | null = null;

  stepsValue: number = 0;

  activityValue: number = 0;

  sleepValue: number = 0;

  company: CompanyDTO | null = null;

  loading: boolean = false;

  error: string | null = null;

  dataLoaded: boolean = false;

  get to(): string {
    return this.$pagesPath.program.programOverview;
  }

  async created(): Promise<void> {
    this.loading = true;
    this.error = null;
    try {
      await this.fetchProgram();
      await this.fetchRewardTracker();
      await this.fetchGatekeeperItems();
      await this.fetchTrackingData();
      await this.fetchCompany();
      this.dataLoaded = true;
    } catch (error) {
      this.error = "Failed to load data";
    } finally {
      this.loading = false;
    }
  }

  async refreshDashboard(): Promise<void> {
    this.error = null;
    try {
      await this.fetchProgram();
      await this.fetchRewardTracker();
      await this.fetchGatekeeperItems();
      await this.fetchTrackingData();
      await this.fetchCompany();
    } catch (error) {
      this.error = "Failed to load data";
    }
  }

  async fetchProgram(): Promise<void> {
    try {
      const program = await this.withLoading(async() => this.cachedApi.getProgram());
      this.program = program;
    } catch (error) {
      void this.growlsStore.growlError("Failed to fetch program");
      throw error;
    }
  }

  async fetchRewardTracker(): Promise<void> {
    try {
      const rewardResponse = await this.withLoading(async() => this.cachedApi.getRewardTracker());
      this.rewardResponse = rewardResponse;
    } catch (error) {
      void this.growlsStore.growlError("Failed to fetch reward tracker");
      throw error;
    }
  }

  async fetchGatekeeperItems(): Promise<void> {
    try {
      const gatekeeperItems: GatekeeperItem[] = await this.withLoading(
        async() => this.api.getGatekeeperItems(
            this.program.id,
            HealthContentActivityResponseType.HealthActivityProgramOverview,
          ),
      );
      this.requiredActivitiesNotCompletedCount = gatekeeperItems.filter(
        (item) => item.isRequired && item.status !== GatekeeperStatus.completed,
      ).length;
    } catch (error) {
      void this.growlsStore.growlError("Failed to fetch gatekeeper items");
      throw error;
    }
  }

  async fetchTrackingData(): Promise<void> {
    const numberOfDays = 7;
    const startDate: Date = getMondayOfTheWeek(new Date());
    const days: Date[] = new Array(numberOfDays).fill("")
    .map((day, index) => addToDate(startDate, {days: index}));

    await this.fetchStepsTrackingItems(days);
    await this.fetchActivityTrackingItems(days);
    await this.fetchSleepTrackingItems(days);
  }

  async fetchStepsTrackingItems(days: Date[]): Promise<void> {
    try {
      const stepsTrackingItems = await this.api.getTrackingList({
        startDate: convertDateToServerFormat(days[0]),
        endDate: convertDateToServerFormat(days[days.length - 1]),
        taskType: TaskType.steps,
      });
      this.stepsValue= stepsTrackingItems.reduce((acc, item) => formatDate(item.date) == formatDate(this.currentDay) ? acc + item.trackedAmount : acc, 0);
    } catch (error) {
      void this.growlsStore.growlError("Failed to fetch steps tracking items");
      throw error;
  }
}

  async fetchActivityTrackingItems(days: Date[]): Promise<void> {
    try {
      const activityTrackingItems = await this.api.getTrackingList({
        startDate: convertDateToServerFormat(days[0]),
        endDate: convertDateToServerFormat(days[days.length - 1]),
        taskType: Object.values(TaskType)
          .filter((taskType) => taskType.includes("time") || taskType == TaskType.general_physical_activity_time)
          .filter((taskType) => taskType != TaskType.sleep_time)
          .join(","),
      });
      this.activityValue = activityTrackingItems.reduce((acc, item) => formatDate(item.date) == formatDate(this.currentDay) ? acc + Math.floor(item.trackedAmount / 60) : acc, 0);
    } catch (error) {
      void this.growlsStore.growlError("Failed to fetch activity tracking items");
      throw error;
    }
  }

  async fetchSleepTrackingItems(days: Date[]): Promise<void> {
    try {
      const sleepTrackingItems = await this.api.getTrackingList({
        startDate: convertDateToServerFormat(days[0]),
        endDate: convertDateToServerFormat(days[days.length - 1]),
        taskType: TaskType.sleep_time,
      });
      this.sleepValue = parseFloat(sleepTrackingItems.reduce((acc, item) => formatDate(item.date) == formatDate(this.currentDay) ? acc + item.trackedAmount / 3600 : acc, 0).toFixed(1));
    } catch (error) {
      void this.growlsStore.growlError("Failed to fetch sleep tracking items");
      throw error;
    }
  }

  async fetchCompany(): Promise<void> {
    try {
      const company = await this.api.getCompany();
      this.company = company;
    } catch (error) {
      void this.growlsStore.growlError("Failed to fetch company information");
      throw error;
    }
  }
}
</script>

<style lang="sass" scoped>
.body
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  background-image: url("@/assets/img/background-wave.svg")
  background-position-y: -1rem
  background-repeat: no-repeat
  background-size: contain
  color: $color-primary-hc-blue-100
  @include Asap700
  position: absolute
  min-height: 100vh
  width: 100%

.todo-progress-container
  display: flex

.loading
  align-content: stretch
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  justify-content: center
  align-items: center
  width: 100%
  margin: 6rem 0

@media (max-width: 1500px)
  .body
    padding: 1rem

  .todo-progress-container
    flex-direction: column

  .loading
    margin: 3rem 0

@media (max-width: 1200px)
  .body
    padding: 0.5rem

  .todo-progress-container
    padding: 0.5rem

  .loading
    margin: 2rem 0

@media (max-width: 900px)
  .body
    padding: 0.25rem

  .todo-progress-container
    padding: 0

  .loading
    margin: 1rem 0

@media (max-width: 600px)
  .body
    padding: 0

  .todo-progress-container
    padding: 0

  .loading
    margin: 0.5rem 0
</style>
