<template>
    <div class="mfa-section">
        <div class="field-set">
          <div v-if="!showMfaSelection">
            <label for="mfaToken">MFA Token Verification</label>
            <input-text
                id="mfaToken"
                v-model="mfaToken"
                placeholder="Enter MFA token"
                :maxlength="maxTokenLength"
                required
                @keyup.enter="verifyMfaTokenOnEnter"
            />
            <div class="timer">
              Time remaining: {{ countdown }}
            </div>
            <error-text :error="error"/>
            <div></div>
            <button-primary
                class="verify-mfa-btn"
                type="button"
                text="Verify MFA"
                @click="verifyMfaToken"
            />

          </div>
          <div v-else>
            <label for="mfaToken" class="header-txt"><h3>Select MFA Delivery Method</h3></label>
            <div></div>
            <br>
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div style="display: flex; align-items: center;">
                <input-radio
                    id="email-mfa"
                    :model-value="isEmailSelected"
                    :checked="isEmailSelected"
                    required
                    name="email"
                    label=""
                    :value="true"
                    style="padding-top: 4px;"
                    @update:model-value="updateIsEmailSelected"
                />
                <img :src="getIcon(true)" style="padding-right: 10px; padding-left:10px;" />
                <p>{{ userInfo?.email }}</p>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div style="display: flex; align-items: center;">
                <input-radio
                    id="phone-mfa"
                    :model-value="isPhoneSelected"
                    :checked="isPhoneSelected"
                    required
                    name="phone"
                    label=""
                    :value="false"
                    style="padding-top: 4px;"
                    @update:model-value="updateIsPhoneSelected"
                />
                <img :src="getIcon(false)" style="padding-right: 10px; padding-left:10px;" />
                <p>{{ userInfo?.phoneNumber }} (SMS)</p>
              </div>
            </div>

            <div style="padding-top: 30px;"></div>
            <button-primary
                class="send-mfa-btn"
                type="button"
                text="Continue"
                @click="selectMFA()"
            />
            <div style="padding-top: 40px;"></div>
            <error-text :error="error"/>

          </div>
        </div>
    </div>
  <div>
    <p class="text" style="">
      If you have any problems, please contact HealthCheck360 at: <b><a class="phone" href="tel:+18665110360">1-866-511-0360</a></b>
      or <b><a href="mailto:support@healthcheck360.com" class="bold">support@healthcheck360.com</a></b>
    </p>
  </div>
  </template>
  
<script lang="ts">
  import {Component} from "vue-property-decorator";
  import InputText from "@/vue/atoms/input-text.vue";
  import ButtonPrimary from "@/vue/atoms/button-primary.vue";
  import InputCheck from "@/vue/atoms/input-check.vue";
  import InputTextarea from "@/vue/atoms/input-textarea.vue";
  import type {MfaSendRequestDTO, MfaVerificationRequestDTO, CensusData} from "@/ts/types/dto/auth.dto";
  import ErrorText from "@/vue/atoms/error-text.vue";
  import {mixins} from "vue-class-component";
  import {
    LoadingMixin,
  } from "@/ts/mixins/loading-mixin";
  import {OnboardingState, OnboardingStoreMixin} from "@/ts/store/onboarding/onboarding-store-instance";
  import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
  import type { PropType } from 'vue';
  import phone from "@/assets/img/icons/phone.svg";
  import sendEmail from "@/assets/img/icons/send-email.svg";
  import InputRadio from "@/vue/atoms/input-radio.vue";
  
  @Component({
    name: "mfa-input",
    components: {
      InputRadio,
      ErrorText,
      InputTextarea,
      InputCheck,
      InputText,
      ButtonPrimary,
    },
    props: {
      userInfo: {
        type: Object as PropType<CensusData>,
        required: false,
        default: null
      }
    }
  })
  export default class mfaInput extends mixins(LoadingMixin, OnboardingStoreMixin, DefaultStoreMixin) { 
    mfaToken: string = "";
    maxTokenLength: number = 10;
    showMfaSelection: boolean = false;
    showMfaInput: boolean = false;
    userInfo!: CensusData | null;
    timer: number | null = null;
    isEmailSelected: boolean = true;
    isPhoneSelected: boolean = false;
  
    countdown: string | null = null;

    mounted() {
      this.showMfaSelection = true;
      //this.startTimer();
    }
  
    // Utility method to validate the redirect URI
    isValidRedirectUri(uri: string): boolean {
      // Basic validation: check if the URI is a valid URL
      try {
        new URL(uri);
        return true;
      } catch (e) {
        return false;
      }
  
      // todo: Add more sophisticated checks as necessary, such as ensuring the URI belongs to your domain
    }
    async selectMFA(): Promise<void> {
      const mfaToken = localStorage.getItem("mfaToken"); // Retrieve the authToken stored locally
      if (!mfaToken) {
        this.error = "No auth token available.";
        return;
      }

      try {
        const request: MfaSendRequestDTO = {
          AuthCode: "",
          DeliveryMethod : this.isEmailSelected ? 'email' : 'sms',
          MfaToken: mfaToken,
        };


        await this.$api.sendMfaToken(request);
        this.showMfaSelection = false;
        this.showMfaInput = true;
        this.startTimer();
        this.error = "";
      } catch (error) {
        console.error(error);
        this.error = "Failed to send MFA token.";
      }
    }

    getIcon(isEmail: boolean): string {
      switch (isEmail) {
        case true:
          return sendEmail;
        case false:
          return phone;
        default:
          return "";
      }
    }
  
    async verifyMfaToken(): Promise<void> {
      const authToken = localStorage.getItem("mfaToken"); // Retrieve the authToken stored locally
      if (!authToken) {
        this.error = "No auth token available.";
        return;
      }
  
      try {
        // Construct the request object with both tokens
        const request: MfaVerificationRequestDTO = {
          AuthCode: authToken,
          MfaToken: this.mfaToken,
        };
  
        // Make the API call with the constructed request
        const verificationResult = await this.$api.verifyMfaToken(request);
        if (verificationResult.sessionToken && verificationResult.refreshToken) {
          this.defaultStore.setAuthTokens(verificationResult); // Update the auth tokens in your store

          if (this.timer !== null) {
            clearInterval(this.timer);
          }

          void this.$router.push("/"); // Navigate to the home page or another appropriate route
        } else {
          // If the verificationResult doesn't include the expected tokens, handle it as a failure
          this.error = "MFA verification failed. Please try again.";
        }
      } catch (error) {
        console.error(error);
        this.error = "Failed to verify MFA token.";
      }
    }
  
    startTimer() {
      const duration = 10 * 60; // 10 minutes
      let time = duration;
      this.timer = window.setInterval(() => { // Use window.setInterval to clarify the browser context
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
  
        this.countdown = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
        time--;
  
        if (time < 0) {
          clearInterval(this.timer!); // Type assertion to number
          this.showMfaInput = false;
          this.error = "MFA token verification timed out. Please start the login process again.";
        }
      }, 1000) as unknown as number; // Type assertion if necessary
    }
  
  
    beforeUnmount() {
      if (this.timer !== null) {
        clearInterval(this.timer);
      }
    }
  
    verifyMfaTokenOnEnter(event: KeyboardEvent): void {
      event.preventDefault(); // Prevent the default form submission
      this.verifyMfaToken();
    }


    updateIsEmailSelected(value: boolean): boolean {
      this.isEmailSelected = value;
      this.isPhoneSelected = !value;
      return value;
    }

    updateIsPhoneSelected(value: boolean): boolean {
      this.isEmailSelected = !value;
      this.isPhoneSelected = value;
      return value;
    }
  }


</script>
<style lang="sass" scoped>

.mfa-section
    margin-right: 80px
    margin-left: 80px
    display: flex
    flex-direction: column
    align-items: center

.timer
    margin-top: 10px
    font-size: 16px

.verify-mfa-btn
    margin-bottom: 40px
    margin-top: 40px

.send-mfa-btn
  margin-bottom: 10px
  margin-top: 10px

.header-txt
  @include Asap700
  color: $color-primary-hc-blue-100

.send-mfa-txt
  @include Asap700
  color: $color-primary-hc-blue-100
  display: flex
  justify-content: center
  max-width: 260px
  padding-top: 10px
  padding-bottom: 10px

.text
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 12px
  line-height: 24px
  letter-spacing: 0.1px

  span
    @include Roboto700
</style>

