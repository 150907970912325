<template>
  <register-page
    :prev-page="$pagesPath.auth.additional"
    :current-step="6"
    title="My Motivation"
    subtitle="Write down your motivation for making or maintaining your health goals!"
    :loading="loading"
    button-text="Create Account"
    @next="submit(false)"
  >
    <div class="container">
      <input-textarea
        id="motivation"
        v-model="motivationJustTyped"
        name="motivation"
        class="textarea"
        placeholder="motivation"
      />
      <input-check
        id="check-tos"
        v-model="termsOfServiceApproved"
        class="agree-checkbox"
        required
        name="check-tos"
      >
        I agree to the
        <router-link :to="$pagesPath.auth.termsOfService" target="_blank" class="agree-checkbox-link">
          Terms of service
        </router-link>
        of HealthCheck360
      </input-check>

      <input-check
        id="check-texting"
        v-model="textingAgreementApproved"
        class="agree-checkbox"
        name="check-texting"
      >
        I agree to receive SMS text messages from HealthCheck360 for my wellness program updates.
        <div class="texting-agreement-line">
          I understand that message and data rates may apply. Text STOP to unsubscribe and HELP for help.
        </div>
      </input-check>

      <div class="privacy-policy-line">
        Learn more about the HealthCheck360
        <router-link :to="$pagesPath.auth.privacyPolicy" target="_blank" class="agree-checkbox-link">
          Privacy Policy
        </router-link>
      </div>

      <error-text :error="error"/>
    </div>
  </register-page>

  <email-confirmation-block-popup
      :displayed="isBlockUntilEmailConfirmed"
      @update:displayed="handleEmailConfirmed"
    />

  <popup-modal :model-value="showMfaInput" :hide-close-button="true">
    <div class="content"  v-if="showMfaInput">
      <h3 class="title" style="margin-bottom: 40px;">
        Please Enter MFA Token
      </h3>
        <mfa-input :user-info="userInfo" />
    </div>
  </popup-modal>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import InputText from "@/vue/atoms/input-text.vue";
import RegisterPage from "@/vue/templates/register-page.vue";
import InputCheck from "@/vue/atoms/input-check.vue";
import InputTextarea from "@/vue/atoms/input-textarea.vue";
import {
  AuthRegisterRequestDTO,
  AuthLoginResponseDTO,
  MfaVerificationRequestDTO,
  CensusData
} from "@/ts/types/dto/auth.dto";
import ErrorText from "@/vue/atoms/error-text.vue";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import type {
  AdditionalData,
  CompanyInformation, Interests,
  RegistrationData,
  VerificationData,
} from "@/ts/types/store/onboarding-store-types";
import {OnboardingState, OnboardingStoreMixin} from "@/ts/store/onboarding/onboarding-store-instance";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import EmailConfirmationBlockPopup from "@/vue/organisms/email-confirmation-block-popup.vue";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import MfaInput from "@/vue/organisms/mfa-input.vue";

@Component({
  name: "Motivation",
  components: {
    ErrorText,
    InputTextarea,
    InputCheck,
    RegisterPage,
    InputText,
    PopupModal,
    EmailConfirmationBlockPopup,
    MfaInput
  },
})
export default class MotivationPage extends mixins(LoadingMixin, OnboardingStoreMixin, DefaultStoreMixin) {
  motivationJustTyped: string = "";

  termsOfServiceApproved: boolean = false;

  textingAgreementApproved: boolean = true;

  isBlockUntilEmailConfirmed: boolean = false;

  showMfaInput: boolean = false;

  userInfo: CensusData | null = null;

  @OnboardingState
  checkToken!: string | null;

  @OnboardingState
  motivation!: string | null;

  @OnboardingState
  userRegistrationData!: RegistrationData | null;

  @OnboardingState
  userVerificationData!: VerificationData | null;

  @OnboardingState
  userAdditionalData!: AdditionalData | null;

  @OnboardingState
  interests!: Interests;

  created(): void {
    if (!this.userAdditionalData) {
      void this.$router.push(this.$pagesPath.auth.additional);
    }
    if (this.motivation) {
      this.motivationJustTyped = this.motivation;
    }
  }

  @DefaultGrowlError
  async submit(skipRegister: boolean = false): Promise<void> {
    if(!skipRegister)
    {
      // If data is missing onmout will revert to required page
      const data: AuthRegisterRequestDTO = {
            ...this.userAdditionalData!,
            ...this.userRegistrationData!,
            interests: this.interests,
            gender: this.userVerificationData!.gender,
            sex: this.userVerificationData!.sex,
            dateOfBirth: this.userVerificationData!.dateOfBirth,
            motivationQuote: this.motivationJustTyped,
            hasConsentedToTexting: this.textingAgreementApproved,
          };
          // save in case network error
          this.onboardingStore.setMotivation(this.motivationJustTyped);
          await this.$api.register(data, this.checkToken!);
    }

    if (this.userRegistrationData?.password && this.userRegistrationData?.username) {
      // Auto-login user

      try {
          const response: AuthLoginResponseDTO = await this.$api.login({
          password: this.userRegistrationData.password,
          username: this.userRegistrationData.username,
        });
        this.defaultStore.setAuthTokens(response);

        if (response.mfaToken) {
          this.hideEmailConfirmationBlock();
          this.userInfo = response.censusData ?? null;
          this.showMfaInput = true;
        } else {
          void this.$router.push("/");
          return;
        }
      } catch (error) {
        const err = error as Error;
        if (typeof err.message === "string" && err.message.includes("(403)")) {
          this.showEmailConfirmationBlock();
          return;
        } else {
          throw error;
        }
      }

    } else {
      throw new Error("Registration login failed");
    }
    // clear data so it's not store if user decides to register another acc
    this.onboardingStore.setUserRegistrationData(null);
    this.onboardingStore.setUserVerificationData(null);
    this.onboardingStore.setAdditionalData(null);
    this.onboardingStore.setCompanyInformation(null);
    this.onboardingStore.setMotivation(null);
    this.onboardingStore.setCheckToken(null);
    const redirectUrl = localStorage.getItem("redirectUrl");

    if (localStorage.getItem("companyCode")) {
      localStorage.removeItem("companyCode");
    }

    if (redirectUrl) {
      localStorage.removeItem("redirectUrl");
      window.location.href = redirectUrl;
    }
  }

  showEmailConfirmationBlock(): void {
    this.isBlockUntilEmailConfirmed = true;
  }

  hideEmailConfirmationBlock(): void {
    this.isBlockUntilEmailConfirmed = false;
  }

  handleEmailConfirmed(value: boolean) {
    this.submit(true);
  }
}
</script>

<style lang="sass" scoped>

.container
  width: 100%

.agree-checkbox
  @include Roboto700
  font-size: 14px
  margin-bottom: 10px

.agree-checkbox-link
  color: $color-secondary-state-blue-100
  font-weight: 700

.textarea
  margin-bottom: 32px

.texting-agreement-line
  margin-left: 38px

.privacy-policy-line
  margin-top: 15px
  margin-left: 38px
  font-size: 14px

.content
  display: flex
  flex-direction: column
  max-width: 652px

.title
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 28px
  line-height: 36px
  margin-top: 0
  margin-bottom: 30px

.text
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 16px
  line-height: 24px
  letter-spacing: 0.1px

  span
    @include Roboto700

</style>
