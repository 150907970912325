<template>
  <div class="container">
    <field-set id="phone-number" label="Cell Phone Number">
      <edit-phone-number-input v-model="phoneNumber"/>
    </field-set>
    <field-set id="email" label="Email">
      <edit-email-input v-model="email"/>
    </field-set>

    <edit-address1 v-model="address1"/>
    <edit-address2 v-model="address2"/>
    <edit-city v-model="city"/>
    <edit-state v-model="state"/>
    <edit-postcode v-model="postcode"/>
    <div v-if="!hideGender">
    <field-set id="gender" label="Gender" >
      <input-drop-down
        id="gender"
        placeholder="Gender"
        :options="genderDisplayOptions"
        :model-value="genderDisplayValue"
        required
        @update:model-value="onGenderUpdated"
      />
    </field-set>
  </div></div>
</template>

  <script lang="ts" setup>
  import RegisterPage from "@/vue/templates/register-page.vue";
  import EditAddress1 from "@/vue/molecules/edit-field/edit-address-1.vue";
  import EditAddress2 from "@/vue/molecules/edit-field/edit-address-2.vue";
  import EditCity from "@/vue/molecules/edit-field/edit-city.vue";
  import EditState from "@/vue/molecules/edit-field/edit-state.vue";
  import EditPostcode from "@/vue/molecules/edit-field/edit-postcode.vue";
  import EditPhoneNumberInput from "@/vue/molecules/edit-field/edit-phone-number-input.vue";
  import FieldSet from "@/vue/molecules/field-set.vue";
  import {computed, inject, onMounted, onUnmounted, ref} from "vue";
  import {OnboardingStore} from "@/ts/store/onboarding/onboarding-store";
  import {getModule} from "vuex-module-decorators";
  import {vueStore} from "@/ts/store/vue-store";
  import {useRouter} from "vue-router";
  import type {PagesPath} from "@/ts/router/pages-path";
  import type {CachedApi} from "@/ts/classes/cached-api";
import {SexType, type CensusData} from "@/ts/types/dto/auth.dto";
import EditEmailInput from "@/vue/molecules/edit-field/edit-email-input.vue";
import EditGenderSelect from "@/vue/molecules/edit-field/edit-gender-select.vue";
import {GenderType} from "@/ts/types/component/input.select";
import {QSelect} from "quasar";
import InputDropDown from "@/vue/atoms/input-drop-down.vue";

  const invalidCharsRegex: RegExp = /[!@$%^&*(),?":{}|<>]/g; // if this is true, it means invalid
  const phoneRegex: RegExp = /^\+1\d{10,14}$/; // if this is true, it means VALID

  const phoneNumber = ref<string>("+1");
  const address1 = ref<string>("");
  const address2 = ref<string>("");
  const city = ref<string>("");
  const state = ref<string>("");
  const postcode = ref<string>("");
  const gender = ref<GenderType>();
  const genderDisplayValue = ref<string>('');
  const onboardingStore: OnboardingStore = getModule(OnboardingStore, vueStore);
  const router = useRouter();
  const $pagesPath = inject<PagesPath>("$pagesPath")!;
  const $cachedApi = inject<CachedApi>("$cachedApi")!;
  const censusData = ref<CensusData>();
  const email = ref<string>("");
  const warnTxt = ref<string>("");
  const genderDisplayOptions = [
    "Female",
    "Male",
    "Non Binary",

  ];
  const hideGender = computed((): boolean => {
    let strVal = localStorage.getItem("hideGender");
   return strVal === "true";
});
  const companyCode = ref<string|null>('');
const props = defineProps<{
  asComponent: boolean;
}>();

  onMounted(() => {
    if (!props.asComponent) {
      void router.push($pagesPath.auth.onboarding);
    }
    populateData();
  });

  const populateData = (): void => {
    if (onboardingStore.userAdditionalData) {
      address1.value = onboardingStore.userAdditionalData!.address1;
      city.value = onboardingStore.userAdditionalData!.city;
      address2.value = onboardingStore.userAdditionalData!.address2;
      const storedNumber = onboardingStore.userAdditionalData!.phoneNumber;
      phoneNumber.value = storedNumber && !(storedNumber!.startsWith('+1'))  ? `+1${storedNumber}` : storedNumber && storedNumber.startsWith('+1') ? storedNumber  : '+1';
      postcode.value = onboardingStore.userAdditionalData!.postcode || "";
      state.value = onboardingStore.userAdditionalData!.state;
      if (onboardingStore.userAdditionalData!.email) {
        email.value = onboardingStore.userAdditionalData!.email;
      }
      if(onboardingStore.userAdditionalData?.gender){
       onGenderUpdated(onboardingStore.userAdditionalData?.gender);
        
      }
    } else if (onboardingStore.userCensusData) {
      censusData.value = onboardingStore.userCensusData!;
      if (censusData.value) {
        address1.value = censusData.value?.address1 || "";
        city.value = censusData.value?.city || "";
        address2.value = censusData.value?.address2 || "";
        const storedNumber = censusData.value?.phoneNumber;
        phoneNumber.value =  storedNumber && !(storedNumber.startsWith('+1'))  ? `+1${storedNumber}` : storedNumber && storedNumber.startsWith('+1') ? storedNumber  : '+1';
        postcode.value = censusData.value?.zip || "";
        state.value = censusData.value?.state || "";
        if (censusData!.value.email) {
        email.value = censusData!.value.email;
      }
      if(hideGender.value === true && censusData!.value.gender){
        gender.value = censusData.value.gender;
      }
      }
    }
  }
  const submit = (): string => {
    let warning: string = "";

    if (!address1.value ||
      (!phoneNumber.value || phoneNumber.value == "+1") ||
      !email.value ||
      !city.value ||
      !state.value ||
      !postcode.value ||
      !gender.value) {
      warning = "Please fill out empty fields! Missing fields: ";
      if (!address1.value) {
        warning += " address1,";
      }

      if (!phoneNumber.value || phoneNumber.value == "+1") {
        warning += " phone number,";
      }

      if (!email.value) {
        warning += " email,";
      }
      if (!city.value) {
        warning += " city,";
      }
      if (!state.value) {
        warning += " state,";
      }
      if (!postcode.value) {
        warning += " zip,";
      }
      if (!gender.value) {
        warning += " gender,";
      }
      if (warning.endsWith(",")) {
        warning = warning.substring(0, warning.length - 1);
        warning += ".";
      }
    }

    if (!phoneRegex.test(phoneNumber.value)) {
      warning += " Phone number should have +1 prefix and between 10 and 14 digits.";
    }

    if (invalidCharsRegex.test(address1.value) ||
      invalidCharsRegex.test(address2.value) ||
      invalidCharsRegex.test(city.value) ||
      invalidCharsRegex.test(postcode.value)) {
      warning += " Address contains invalid characters. Please correct to proceed.";
    }
    warnTxt.value = warning;
    onboardingStore.setAdditionalData({
      address1: address1.value,
      city: city.value,
      address2: address2.value,
      phoneNumber: phoneNumber.value,
      postcode: postcode.value,
      state: state.value,
      email: email.value,
      gender: gender.value,

    });
    return warnTxt.value;
  };
  defineExpose({submit}); // This is here so the parent component can call it

  const onGenderUpdated = (val: string) : GenderType|undefined => {
    switch (val.toLowerCase()) {
      case GenderType.MALE:
        genderDisplayValue.value = 'Male';
        gender.value = GenderType.MALE;
        break;
      case GenderType.FEMALE:
        genderDisplayValue.value = 'Female';
        gender.value = GenderType.FEMALE;
        break;
      case "non_binary":
        genderDisplayValue.value = 'Non Binary';
        gender.value = GenderType.NON_BINARY;
        break;
      case "non binary":
        genderDisplayValue.value = 'Non Binary';
        gender.value = GenderType.NON_BINARY;
        break;
      default:
        gender.value = undefined;
    }
    return gender.value
  };
  // data-preload for next page
  void $cachedApi.getInterestOptions();

  </script>

  <style lang="sass" scoped>
  .title
    color: $color-primary-hc-blue-100
    margin-bottom: 5rem
    text-align: center

  .container
    max-width: max-container-width(24px, $container-width, 2)
    align-items: unset
    margin: auto
    @include flex-container(24px, $container-width, 2)
    align-items: flex-start !important
    align-content: flex-start !important


  .warn-txt
    display: block
    color: $warning

  .warn-placeholder
    width: 28px
    display: inline-block

  .warn-icon
    width: 24px
    position: absolute
    top: 15px
    left: 15px

  .profile-info
    color: $color-secondary-state-blue-100
    font-size: 14px
    line-height: 22px
    letter-spacing: 0.2px
    @include Roboto400

  .q-select
    @include input-field
  </style>
