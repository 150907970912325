<template>
  <popup-modal :model-value="displayed" :hide-close-button="true" @update:model-value="closePopup">
    <div class="content">
      <h3 class="title">
        Please confirm your email address
      </h3>
        <p class="text">
          In order to keep your account secure, we need you to <b>confirm your email address.</b>
          Please check your inbox and follow the instructions. If you can not find the email, please check your spam folder.
        </p>
        <ol>
          <li>Go to your inbox and click the link in the email from <b>support@healthcheck360.com</b> to confirm your email address.</li>
          <li>Once your email is confirmed, please navigate back to this page and click <b>Continue.</b></li>
          <li>After clicking Continue, you will be prompted for a <b>Multi-factor Authentication Code (MFA)</b> that will be sent to your newly confirmed email inbox.</li>
        </ol>
      <p class="text">
        If you have any problems, please contact HealthCheck360 at: <b><a class="phone" href="tel:+18665110360">1-866-511-0360</a></b>
        or <b><a href="mailto:support@healthcheck360.com" class="bold">support@healthcheck360.com</a></b>
      </p>
        <form @submit.prevent="updateDisplayed">
          <error-text :error="error"/>
          <button-primary
            class="button"
            :loading="loading"
            type="submit"
            text="Continue"
            theme="dark"
          />
        </form>
    </div>
  </popup-modal>
</template>




<script lang="ts">
import {Component, Prop, Emit} from "vue-property-decorator";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputText from "@/vue/atoms/input-text.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import EditEmailInput from "@/vue/molecules/edit-field/edit-email-input.vue";
import {mixins} from "vue-class-component";
import {LoadingMixin, DefaultGrowlError} from "@/ts/mixins/loading-mixin";

@Component({
  name: "EmailConfirmationBlockPopup",
  components: {
    ButtonPrimary,
    ErrorText,
    FieldSet,
    InputText,
    PopupModal,
    EditEmailInput
  }
})
export default class EmailConfirmationBlockPopup extends mixins(LoadingMixin) {
  @Prop()
  displayed!: boolean;

  username: string = "";

  requestSent: boolean = false;

  email: string = "test";

  @Emit('closePopup')
  closePopup(): boolean {
    this.requestSent = false;
    return false;
  }

  @Emit('update:displayed')
  async updateDisplayed(): Promise<void> {

  }


  @DefaultGrowlError
  async sendPasswordReset(): Promise<void> {
    await this.$api.requestRecoveryEmail({
      username: this.username,
    });
    this.username = "";
    this.requestSent = true;
  }
}

</script>

<style lang="sass" scoped>
.content
  display: flex
  flex-direction: column
  max-width: 652px
  min-width: 550px

.title
  @include Asap700
  color: $color-primary-hc-blue-100
  font-size: 28px
  line-height: 36px
  margin-top: 0
  margin-bottom: 16px

.text
  @include Roboto400
  color: $color-primary-hc-blue-100
  font-size: 16px
  line-height: 24px
  letter-spacing: 0.1px

  span
    @include Roboto700

form
  display: flex
  flex-direction: column
  margin-top: 40px

.label
  font-size: 16px
  line-height: 22px
  letter-spacing: 0.15px

.button
  margin-top: 48px
  align-self: flex-end

img
  align-self: center
  height: 132px
  width: 183px
li
  margin-bottom: 5px
</style>
