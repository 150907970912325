/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-lines */
/* eslint-disable import/no-internal-modules */

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedformat from "dayjs/plugin/localizedFormat";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedformat);
dayjs.extend(isoWeek);

export function getDayDD(date: Date): string {
  return String(date.getDate()).padStart(2, "0");
}

export function getMonthMM(date: Date): string {
  return String(date.getMonth() + 1).padStart(2, "0");
}

function getYearYYYY(date: Date): string {
  return String(date.getFullYear()).padStart(4, "0");
}

function convertSecToMinHoursDays(seconds: number, format: {
  sec: string;
  min: string;
  hr: string;
  days: string;
}): {
  measure: number;
  unit: string;
} {
  if (!seconds) {
    return {
      measure: 0,
      unit: format.sec,
    };
  }
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return {
      measure: days,
      unit: format.days,
    };
  }

  if (hours > 0) {
    return {
      measure: hours,
      unit: format.hr,
    };
  }

  if (minutes > 0) {
    return {
      measure: minutes,
      unit: format.min,
    };
  }

  return {
    measure: seconds,
    unit: format.sec,
  };
}

function ensureTypeDate(date: Date | string): Date {
  if (typeof date === "string") {
    return parseDate(date);
  }
  return date;
}

export function suffix(day: number): string {
  if (day > 3 && day < 21) {
    return "th";
  }

  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function formatDuration(seconds: number): string {
  // see also function secondsToNiceReadable
  if (!seconds) {
    return "Duration not available";
  }
  const {measure, unit} = convertSecToMinHoursDays(seconds, {
    sec: "seconds",
    min: "minutes",
    hr: "hours",
    days: "days",
  });
  return `${measure} ${unit}`;
}

/**
 * displays format in this format: 2h 10m
 */
export function formatDurationNiceReadable(seconds: number): string {
  const minutes = seconds / 60;
  const hours = Math.floor(minutes / 60);
  const hoursStr = hours ? `${hours}h ` : "";
  const min = Math.ceil(minutes % 60);
  let minStr = "";

  if (min || !hours) {
    minStr = hours ? `${min}m` : `${min} min`;
  }

  return hoursStr + minStr;
}

/**
 * @param dateStr expect ISO formats '2023-02-06' or '2023-02-06T07:59:32.727Z'
 */
export function parseDate(dateStr: string): Date {
  if (dateStr.length > 10) {
    // expect ISO-string with time, like: 2023-02-06T07:59:32.727Z
    return new Date(dateStr);
  }
  // expect format 2023-02-06 (without time) -> set date to localtime start of the day

  // YYYY-MM-DD will be parsed as UTC-start-of-the-day
  const dayUtc = new Date(dateStr);
  // create new date with LOCAL-start-of-the-day
  const dayLocal = new Date(dayUtc.getUTCFullYear(), dayUtc.getUTCMonth(), dayUtc.getUTCDate());
  // years before 1900, e.g. year 0001 will be transformed to 1901 otherwise
  dayLocal.setFullYear(dayUtc.getUTCFullYear());
  return dayLocal;
}

// MM/DD/YYYY
export function formatDate(date: Date | string): string {
  if (typeof date === "string" && date.startsWith("0001")) {
    return "—";
  }

  const _date = ensureTypeDate(date);
  return dayjs(_date).format("MM/DD/YYYY");
}

// Feb 7, 2023
export function formatDateShortMonthName(date: Date | string): string {
  const _date = ensureTypeDate(date);
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(_date);
}

// July 7, 2023
export function formatDateLongMonthName(date: Date | string, includeYear: boolean = true): string {
  const _date = ensureTypeDate(date);
  if (includeYear) {
    return dayjs(_date).format("MMMM DD, YYYY");
  }

  return dayjs(_date).format("MMMM DD");
}

// Thursday, August 16, 2018 8:02 PM
export function dateToFullFormat(date: Date | string): string {
  const _date = ensureTypeDate(date);

  return dayjs(_date).format("LLLL");
}

// 08/16/2024 8:02 PM
export function formatInLocalDatetimeString(date: Date | string): string {
  const _date = ensureTypeDate(date);

  return dayjs(_date).format("MM/DD/YYYY hh:mm A");
}

export function formatDateForCalendar(date: Date): string {
  // TODO: used for google-calendar, try to replace with date.getIsoString(); or convertDateTimeToServerFormat
  const HH = String(date.getHours()).padStart(2, "0");
  const yyyy = getYearYYYY(date);
  const mm = getMonthMM(date);
  const dd = getDayDD(date);
  const MM = String(date.getMinutes()).padStart(2, "0");
  const SS = String(date.getSeconds()).padStart(2, "0");
  return `${yyyy}${mm}${dd}T${HH}${MM}${SS}`;
}

export function convertDateToServerFormat(date: Date): string {
  return dayjs(date).format("YYYY-MM-DD");
}

export function convertDateTimeToServerFormat(date: Date): string {
  return date.toISOString();
}

export function getCurrentTimestampForRequestHeader(): string {
  return dayjs().format("YYYY-MM-DDTHH:mm:ssZ");
}

export function get12HourTime(date: Date | string): string {
  const _date = ensureTypeDate(date);
  return dayjs(_date).format("h:mm a");
}

export function getDaysInMonth(date: Date | string): number {
  const _date = ensureTypeDate(date);
  return dayjs(_date).daysInMonth()
}

export function getShortDayOfWeek(date: Date): string {
  return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getDay()];
}

export function getShortMonth(date: Date): string {
  const _date = ensureTypeDate(date)
  return dayjs(_date).format("MMM")
}

export function getMonth(date: Date): string {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[date.getMonth()];
}

export function getMonthDay(date: Date): string {
  const dayDiff = Math.floor((Date.now() - date.getTime()) / (1000 * 3600 * 24));
  if (dayDiff === 0) {
    return "Today";
  } else if (dayDiff === 1) {
    return "Yesterday";
  }
  return `${getMonth(date)} ${date.getDate()}`;
}

export function getMonthDayYear(date: Date): string {
  const dayDiff = Math.floor((Date.now() - date.getTime()) / (1000 * 3600 * 24));
  if (dayDiff === 0) {
    return "Today";
  } else if (dayDiff === 1) {
    return "Yesterday";
  }
  return dayjs(date).format("M/D/YY");
}

export function getDayOfWeek(date: Date): string {
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return weekDays[date.getDay()];
}

export function getMonthYear(date: Date): string {
  return `${getMonth(date)} ${date.getFullYear()}`;
}

  export function getUserStrDateWeekMonthDay(date: Date): string {
  const weekDay = getDayOfWeek(date);
  const month = getMonth(date);
  return `${weekDay}, ${month} ${date.getDate()}`;
}

/**
 *  Calculate the difference between today and any date
 *  @return an integer number
 *
 */
export function getRemainNumberOfDays(date: string, dateStart?: string): number {
  // TODO refactor: use day.js

  const _date = parseDate(date);
  const _referenceDate = dateStart ? parseDate(dateStart) : today();

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // time difference between two dates in milliseconds
  const diffInTime = _date.getTime() - _referenceDate.getTime();

  // return rounded diff in time to the day difference
  return Math.ceil(diffInTime / oneDay);
}

export function calculateSecToMinutes(seconds: number): number {
  return Math.floor(seconds / 60);
}

export function convertMinToSec(min: number): number {
  return min * 60;
}

export function convertHoursToSec(hr: number): number {
  return hr * 3600;
}
export function calculateSecToHours(seconds: number): number {
  return Math.floor(seconds / 3600);
}

export function isTimestampBeforeReferenceTimestamp(date: Date | string, referenceDate: Date | string): boolean {
  // TODO refactor: use day.js
  const _date = ensureTypeDate(date);
  const _referenceDate = ensureTypeDate(referenceDate);
  return _date.getTime() < _referenceDate.getTime();
}

export function addToDate(
  date: Date | string,
  addend: Partial<{[unit in dayjs.ManipulateType]: number}>,
): Date {
  const _date = ensureTypeDate(date);
  const keys: dayjs.ManipulateType[] = Object.keys(addend) as dayjs.ManipulateType[];
  return dayjs(_date)
    .add(addend[keys[0]] ?? 0, keys[0] as dayjs.ManipulateType ?? "days")
    .toDate();
}

export function subtractFromDate(
  date: Date | string,
  subtrahend: Partial<{[unit in dayjs.ManipulateType]: number}>,
): Date {
  const _date = ensureTypeDate(date);
  const keys: dayjs.ManipulateType[] = Object.keys(subtrahend) as dayjs.ManipulateType[];
  return dayjs(_date)
    .subtract(subtrahend[keys[0]] ?? 0, keys[0] as dayjs.ManipulateType ?? "days")
    .toDate();
}

export function isSameDay(date: Date | string, referenceDate: Date | string): boolean {
  const _date = ensureTypeDate(date);
  const _referenceDate = ensureTypeDate(referenceDate);
  return dayjs(_date).isSame(_referenceDate, "days");
}

export function isSameYear(date: Date | string, referenceDate: Date | string): boolean {
  const _date = ensureTypeDate(date);
  const _referenceDate = ensureTypeDate(referenceDate);
  return dayjs(_date).isSame(_referenceDate, "years");
}

export function isDayInFuture(date: Date | string): boolean {
  const _date = getStartOfTheDay(ensureTypeDate(date));
  return dayjs(_date).isAfter(today(), "day");
}

export function isTimestampInFuture(date: Date | string): boolean {
  // TODO refactor: use day.js
  const _date = ensureTypeDate(date);
  return _date.getTime() > now().getTime();
}

export function isDateInFuture(date: Date | string, includeToday: boolean = true): boolean {
  const _date = dayjs(date).startOf("day");
  const _today = dayjs().startOf("day");

  return _date.isAfter(_today) || (includeToday && _date.isSame(_today));
}



export function getMondayOfTheWeek(date: Date): Date {
  return dayjs(date)
      .isoWeekday(1)
      .toDate();
}

export function getStartOfTheDay(date: Date): Date {
  return dayjs(date)
    .startOf("day")
    .toDate();
}

export function getStartOfTheMonth(date: Date): Date {
  return dayjs(date)
      .startOf("month")
      .toDate();
}

export function getEndOfTheMonth(date: Date): Date {
  return dayjs(date)
      .endOf("month")
      .toDate();
}

export function getEndOfTheDay(date: Date): Date {
  return dayjs(date)
    .endOf("day")
    .toDate();
}

export function getStartOfToday(): Date {
  return getStartOfTheDay(now());
}

export function getEndOfToday(): Date {
  return getEndOfTheDay(now());
}

export function today(): Date {
  return getStartOfToday();
}

export function now(): Date {
  return new Date();
}

export function isDayBeforeReferenceDay(date: Date | string, referenceDate: Date | string): boolean {
  const _date = ensureTypeDate(date);
  const _referenceDate = ensureTypeDate(referenceDate);
  return dayjs(_date).isBefore(_referenceDate, "day");
}

export function isDayAfterReferenceDay(date: Date | string, referenceDate: Date | string): boolean {
  const _date = ensureTypeDate(date);
  const _referenceDate = ensureTypeDate(referenceDate);
  return dayjs(_date).isAfter(_referenceDate, "day");
}

export function getTimezoneAbbr(): string {
  return dayjs.tz.guess();
}

export function isValidDate(date: Date | string): boolean {
  if (!date) {
return false;
}
  return dayjs(date).isValid();
}

export function getMinutesDiffToNow(referenceDate: Date | string): number {
  const _date = ensureTypeDate(referenceDate);
  return dayjs(now()).diff(_date, "minutes");
}


//Returns 1 for current day
//Includes current day
export function daysSince(date: string | Date | dayjs.Dayjs): number {
  return dayjs().diff(dayjs(date), 'days') + 1;
}